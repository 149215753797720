<template>
  <b-container class="mt-3">
    <b-alert
      :show="dismissCountDown"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
      data-nosnippet
    >
      新项目： <a href="https://flash.zczc.cz">Flash 保存计划</a>，欢迎尝试！
    </b-alert>
  </b-container>
</template>

<script>
export default {
  data: function () {
    return {
      dismissCountDown: 10,
    };
  },
  name: "PromoteBanner",
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>
