<template>
  <div id="index">
    <PromoteBanner />
    <GamesView :game_infos="game_infos" :show_count="true" />
  </div>
</template>

<script>
import { index_max_size } from "@/utils/constants";
import { popular_games } from "@/utils/info/index_info";
import widescreen_mixin from "@/mixins/widescreen_mixin";
import PromoteBanner from "@/components/PromoteBanner";
import GamesView from "@/components/GamesView";

export default {
  name: "index",
  mixins: [widescreen_mixin],
  metaInfo: {
    title: "在线 DOS 游戏",
    titleTemplate: null,
    meta: [
      {
        name: "description",
        content: "在浏览器中在线游玩 DOS 游戏与 DOS 游戏下载！🎮",
      },
      {
        name: "keywords",
        content: "在线, DOS, 游戏, 在线DOS游戏, DOS游戏下载, 下载",
      },
      { property: "og:title", content: "在线 DOS 游戏" },
      { property: "og:url", content: "https://dos.zczc.cz/" },
      {
        property: "og:description",
        content: "在浏览器中在线游玩 DOS 游戏与 DOS 游戏下载！🎮",
      },
    ],
    link: [{ rel: "canonical", href: "https://dos.zczc.cz/" }],
    script: [
      {
        type: "application/ld+json",
        json: {
          "@context": "https://schema.org",
          "@type": "WebSite",
          url: "https://dos.zczc.cz/",
          potentialAction: {
            "@type": "SearchAction",
            target: "https://dos.zczc.cz/search?q={search_term_string}",
            "query-input": "required name=search_term_string",
          },
        },
      },
    ],
  },
  components: {
    GamesView,
    PromoteBanner,
  },
  computed: {
    game_infos: () => popular_games.slice(0, index_max_size),
  },
};
</script>
